// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-index-mdx": () => import("./../../../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---docs-java-installation-md": () => import("./../../../docs/java/installation.md" /* webpackChunkName: "component---docs-java-installation-md" */),
  "component---docs-java-usage-md": () => import("./../../../docs/java/usage.md" /* webpackChunkName: "component---docs-java-usage-md" */),
  "component---docs-javascript-configuration-md": () => import("./../../../docs/javascript/configuration.md" /* webpackChunkName: "component---docs-javascript-configuration-md" */),
  "component---docs-javascript-installation-md": () => import("./../../../docs/javascript/installation.md" /* webpackChunkName: "component---docs-javascript-installation-md" */),
  "component---docs-javascript-usage-md": () => import("./../../../docs/javascript/usage.md" /* webpackChunkName: "component---docs-javascript-usage-md" */),
  "component---docs-php-installation-md": () => import("./../../../docs/php/installation.md" /* webpackChunkName: "component---docs-php-installation-md" */),
  "component---docs-php-usage-md": () => import("./../../../docs/php/usage.md" /* webpackChunkName: "component---docs-php-usage-md" */),
  "component---docs-python-installation-md": () => import("./../../../docs/python/installation.md" /* webpackChunkName: "component---docs-python-installation-md" */),
  "component---docs-python-usage-md": () => import("./../../../docs/python/usage.md" /* webpackChunkName: "component---docs-python-usage-md" */),
  "component---docs-ruby-installation-md": () => import("./../../../docs/ruby/installation.md" /* webpackChunkName: "component---docs-ruby-installation-md" */),
  "component---docs-ruby-usage-md": () => import("./../../../docs/ruby/usage.md" /* webpackChunkName: "component---docs-ruby-usage-md" */),
  "component---readme-md": () => import("./../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

